import Product from '../../../components/Product';
import React from 'react';

export default ({ location }) => {
  return (
    <Product
      location={location}
      title={'Energy Tracker'}
      subtitle={'Know Your Prime-Time'}
      linkToGooglePlay={
        'https://play.google.com/store/apps/details?id=com.energon'
      }
      bulletsTitle={`YOU'RE THE BEST`}
      bulletsSubtitle={'JUST NOT ALL THE TIME'}
      bullets={[
        {
          title: 'Know When You are Gooood',
          description:
            'During the day we have better times, we have worse times. Knowing that — helpful',
          linkToImage: require('./images/016.png'),
        },
        {
          title: 'Improve Your Planning',
          description:
            'Plan your most important task for times when you are at hour best',
          linkToImage: require('./images/023.png'),
        },
        {
          title: 'Get More Done, More Efficiently',
          description: 'Stop wasting time — start nailing it.',
          linkToImage: require('./images/033.png'),
        },
      ]}
    />
  );
};
