import React from 'react';

const YoutubePlayer = ({ id }) => {
  return (
    <iframe
      width={'100%'}
      height={'700'}
      src={`https://www.youtube.com/embed/${id}?controls=0`}
      frameborder={'0'}
      allow={
        'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
      }
      allowfullscreen
    ></iframe>
  );
};

export default YoutubePlayer;
