import { Hidden, Typography } from '@material-ui/core';

import React from 'react';

export default ({ children }) => (
  <div style={{ maxWidth: '800px' }}>
    <Hidden xsDown>
      <Typography
        variant={'h2'}
        style={{
          fontWeight: 'bold',
          lineHeight: 1.1,
        }}
        gutterBottom
      >
        {children}
      </Typography>
    </Hidden>
    <Hidden smUp>
      <Typography variant={'h3'} gutterBottom>
        {children}
      </Typography>
    </Hidden>
  </div>
);
