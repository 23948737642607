import { Grid, Typography } from '@material-ui/core';

import React from 'react';
import useWindowSize from '../hooks/use-window-size';

export default ({
  fullWidth = false,
  title = '',
  color = 'unset',
  children,
  horizontalPadding = 40,
  rightIsFull = false,
}) => {
  const { width } = useWindowSize();

  return (
    <div
      style={{
        paddingTop: horizontalPadding,
        paddingBottom: horizontalPadding,
        backgroundColor: color,
      }}
    >
      <Grid container spacing={width < 600 ? 2 : 8}>
        <Grid item xs={12} sm={1} md={3} lg={3} xl={4}>
          <div style={{ padding: width < 600 ? '12px 12px 0px 12px' : '40px' }}>
            <Typography
              variant={'caption'}
              gutterBottom
              style={{
                fontSize: 14,
                textTransform: 'uppercase',
              }}
            >
              {title}
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={rightIsFull ? 9 : 6}
          lg={rightIsFull ? 9 : 7}
          xl={rightIsFull ? 8 : 5}
        >
          <div style={{ padding: width < 600 ? '0px 12px 12px 12px' : '40px' }}>
            {children}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
