import { Divider, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { green, red, yellow } from '@material-ui/core/colors';

import Layout from './Layout';
import PageTitle from './PageTitle';
import SEO from './seo';
import Section from './Section';
import { SubscriptionForm } from './SubscriptionForm';
import YoutubePlayer from './YoutubePlayer';

const AlgoToSuccess = ({
  title,
  description,
  reverse = false,
  linkToImage = 'https://images.idgesg.net/images/article/2018/10/analytics_binary_code_network_digital-transformation-100777428-large.jpg',
}) => (
  <>
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '40px',
      }}
    >
      {!reverse ? (
        <img
          style={{
            width: '100px',
            borderRadius: 4,
            marginRight: '40px',
          }}
          src={linkToImage}
        />
      ) : null}
      <div>
        <Typography
          variant={'h5'}
          style={{
            marginTop: '24px',
            fontWeight: 'bold',
          }}
        >
          {title}
        </Typography>
        <Typography>{description}</Typography>
      </div>
      {!!reverse ? (
        <img
          style={{
            width: '160px',
            borderRadius: 4,
            marginRight: '40px',
          }}
          src={linkToImage}
        />
      ) : null}
    </div>
  </>
);

export default ({
  location,
  title,
  subtitle,
  _idYoutube,
  linkToGooglePlay,
  linkToAppStore,
  bulletsTitle,
  bulletsSubtitle,
  bullets = [],
}) => {
  return (
    <Layout fullWidth location={location} title={`${title} | Rohov Dmytro`}>
      <Section>
        <PageTitle>{title}</PageTitle>
        <Typography>{subtitle}</Typography>
      </Section>
      <Divider />

      {_idYoutube ? <YoutubePlayer id={_idYoutube} /> : null}

      {!!bullets || bulletsTitle ? (
        <Section title={'Why'} rightIsFull>
          <Typography
            variant={'h2'}
            style={{
              color: yellow[500],
              fontWeight: 900,
              lineHeight: 1.05,
            }}
          >
            {bulletsTitle}
          </Typography>
          <Typography
            variant={'h6'}
            style={{
              color: yellow[500],
              fontWeight: 'bold',
              lineHeight: 1.05,
              marginBottom: '48px',
            }}
            gutterBottom
          >
            {bulletsSubtitle}
          </Typography>
          {bullets.map(({ title, description, linkToImage }) => (
            <AlgoToSuccess
              key={title}
              title={title}
              description={description}
              linkToImage={linkToImage}
              reverse={Math.random() % 2 === 0}
            />
          ))}
        </Section>
      ) : null}

      <Section title={'GET IT'} color={green[700]}>
        <Typography variant={'h3'} style={{ fontWeight: 'bold' }}>
          Available Now
        </Typography>
        <div
          style={{
            display: 'flex',
          }}
        >
          {linkToGooglePlay ? (
            <a target={'_blank'} href={linkToGooglePlay}>
              <img
                style={{
                  marginTop: '20px',
                  marginLeft: '-18px',
                  width: '240px',
                }}
                src={
                  'https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
                }
              />
            </a>
          ) : null}
        </div>
      </Section>

      <Section title={'NEWSLETTER'}>
        <Typography gutterBottom variant={'h3'} style={{ fontWeight: 'bold' }}>
          Deep Information, Regulary Delivered
        </Typography>
        <SubscriptionForm />
      </Section>
    </Layout>
  );
};
